<template>
  <div>
    <Certificate />
  </div>
</template>

<script>
import Certificate from "../components/ReportCenter/Certificate";
export default {
  components: {
    Certificate,
  },
};
</script>

<style></style>
